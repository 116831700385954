











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class IntegrationConfigMapping extends Vue {
  @Prop({ required: true })
  config: any;

  @Prop({ required: true })
  target: any;

  @Prop({ default: true })
  bulkChange: boolean;

  changeAll(event: Event): void {
    const targetOption = (event.target! as HTMLSelectElement).value || null;

    for (const key of Object.keys(this.config.names)) {
      this.target[key] = targetOption;
    }
  }
}
