
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { money as moneyFilter } from '@/lib/filters/money';
import { InvoiceTotals } from '@/lib/services/invoices';
import Popover from '@/components/Popover.vue';

@Component({
  components: {
    Popover,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class TotalsHeader extends Vue {
  @Prop({ required: true })
  readonly totals!: InvoiceTotals;

  get collapseIncl(): boolean {
    return Object.keys(this.totals).length > 2;
  }
}
