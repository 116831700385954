




import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { AnalyticsService } from '@/lib/services';

import { Chart, ChartOptions, ChartDataSets } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { currentSwatch, cssColorToHex } from '@/lib/themes';

const TYPES: string[] = ['turnover', 'customers'];

interface GraphOptions {
  interval?: 'month' | 'year';
  limit?: number;
}

@Component
export default class Graph extends Vue {
  chart: Chart;

  @Prop({ required: true, validator: (x) => TYPES.includes(x) })
  type: string;

  @Prop({ default: () => ({}) })
  options: GraphOptions;

  async mounted(): Promise<void> {
    await this.renderGraph();
  }

  @Watch('type')
  async renderGraph(): Promise<void> {
    switch (this.type) {
      case 'turnover':
        await this.renderTurnover();
        break;
      case 'customers':
        await this.renderCustomers();
        break;
    }
  }

  async renderTurnover(): Promise<void> {
    if (this.chart) {
      this.chart.destroy();
    }

    const data = await AnalyticsService.turnover(this.options.interval);
    if (!this.canvas) {
      return;
    }
    const ctx = this.canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            ...Graph.chartLineStyle(),
            data: data.values,
          },
        ],
      },
      options: Graph.chartOptions(),
    });
  }

  async renderCustomers(): Promise<void> {
    if (this.chart) {
      this.chart.destroy();
    }

    const data = await AnalyticsService.customers(this.options.limit);
    if (!this.canvas) {
      return;
    }
    const ctx = this.canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    const xs = data.map((e) => e.companyname);
    const ys = data.map((e) => e.amount);

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: xs,
        datasets: [
          {
            ...Graph.chartBarStyle(),
            data: ys,
          },
        ],
      },
      options: Graph.chartOptions(),
    });
  }

  static chartBarStyle(): ChartDataSets {
    const swatch = currentSwatch();
    const primary = cssColorToHex(swatch['primary']).substr(0, 7);
    return {
      hoverBackgroundColor: primary + 'C0',
      backgroundColor: primary + '80',
    };
  }

  static chartLineStyle(): ChartDataSets {
    const swatch = currentSwatch();
    return {
      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderColor: 'rgba(0,0,0,0)',
      pointRadius: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: cssColorToHex(swatch['primary']).substr(0, 7),
      pointHoverBorderColor:
        cssColorToHex(swatch['primary-transparent']).substr(0, 7) + 'A0',
      pointHoverBorderWidth: 3,
      backgroundColor:
        cssColorToHex(swatch['primary-transparent']).substr(0, 7) + '34',
      borderColor: cssColorToHex(swatch['primary']).substr(0, 7),
    };
  }

  static chartOptions(): ChartOptions {
    return {
      maintainAspectRatio: false,
      hover: {
        intersect: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 10,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };
  }

  get canvas(): HTMLCanvasElement {
    return this.$refs['canvas'] as HTMLCanvasElement;
  }
}
