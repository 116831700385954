






















































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import StatusPanel from './StatusPanel.vue';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import { ActionButtonList, ActionButton } from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';

@Component({
  components: {
    Popover,
    StatusPanel,
    ActionButtonList,
    ActionButton,
    ViewInvoiceModal,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineHistory extends ArchiveLine {
  get modalTitle(): string {
    if (!this.invoice) {
      return this.$tc('_.invoice');
    }

    return `${this.$tc('_.invoice')} ${this.invoice.number}`;
  }

  get customerNameShort(): string {
    if (this.invoice.receiver.name.length > 20) {
      return this.invoice.receiver.name.slice(0, 19) + '…';
    }
    return this.invoice.receiver.name;
  }
}
