import { Invoice } from '@/models/invoice';

export type ArchiveSearchType =
  | 'invoice'
  | 'quotation'
  | 'recurring'
  | 'draft'
  | 'sepa';
export const ARCHIVE_SEARCH_TYPES: ArchiveSearchType[] = [
  'invoice',
  'quotation',
  'recurring',
  'draft',
  'sepa',
];

export interface ArchiveEntry {
  id: string;
  selected: boolean;
  data?: Invoice;
}

export class ArchiveFilters {
  status: string[] = [];
  mindate: Date | null = null;
  maxdate: Date | null = null;
  minduedate: Date | null = null;
  maxduedate: Date | null = null;
  minamount: number | null = null;
  maxamount: number | null = null;
  minnumber: string | null = null;
  maxnumber: string | null = null;
  invoice_id: string | null = null;
  customer_id: string | null = null;

  get minimal(): Record<string, Date | number | string> {
    const obj: any = {};
    for (const [k, v] of Object.entries(this)) {
      if (v !== null) {
        obj[k] = v;
      }

      if (v instanceof Array) {
        obj[k] = v.join(',');
      }
    }
    return obj;
  }

  isEmpty(): boolean {
    for (const [k, v] of Object.entries(this)) {
      if (v instanceof Array) {
        if (v.length > 0) {
          return false;
        }
        continue;
      }

      if (!!v && k !== 'type') {
        return false;
      }
    }

    return true;
  }
}
