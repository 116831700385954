



































































































































































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import StatusPanel from './StatusPanel.vue';
import CommentPanel from '@/components/archive/comments/CommentPanel.vue';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import {
  ActionButton,
  ActionButtonList,
  ActionRouterLink,
} from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';
import { InvoiceService, MatomoService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { Invoice, InvoiceStatus, InvoiceTextEmail } from '@/models/invoice';
import { InvoiceSendType } from '@/lib/services/invoices';
import { downloadBlob } from '@/lib/download';
import { TrackingEvent } from '@/lib/trackingevent';

@Component({
  components: {
    Popover,
    StatusPanel,
    ActionButtonList,
    ActionButton,
    ActionRouterLink,
    ViewInvoiceModal,
    SendInvoiceModal,
    CommentPanel,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineQuotation extends ArchiveLine {
  showModal: boolean = false;

  get quotationStatus(): boolean {
    if (!this.status) return false;
    return this.status.quotation.status;
  }

  get isDraftQuotation(): boolean {
    if (!this.invoice) return false;
    return this.invoice.status === InvoiceStatus.DRAFT_QUOTATION;
  }

  get isArchivedQuotation(): boolean {
    if (!this.invoice) return false;
    return this.invoice.status === InvoiceStatus.ARCHIVED_QUOTATION;
  }

  get isAcceptedQuotation(): boolean {
    if (!this.status) return false;
    return (
      this.status.quotation.status && this.status.quotation.message === 'ACCEPT'
    );
  }

  convertQuotation(): void {
    this.$router.push({
      name: 'invoice_create',
      query: {
        copy: this.invoice.id,
        convert: 'true',
      },
    });
  }

  async sendQuotation(
    type: string,
    invoice: Invoice,
    emailData: InvoiceTextEmail,
  ): Promise<void> {
    try {
      if (type === 'email') {
        // Email it
        await InvoiceService.send(invoice.id, InvoiceSendType.Mail, {
          text: emailData,
        });
        MatomoService.trackEvent(
          TrackingEvent.invoice().email(invoice.trackingType, invoice.id),
        );
      } else if (type === 'sms') {
        await InvoiceService.send(invoice.id, InvoiceSendType.Sms);
        MatomoService.trackEvent(
          TrackingEvent.invoice().sms(invoice.trackingType, invoice.id),
        );
      } else {
        // Download it
        const response = await InvoiceService.sendDownloadPdf(invoice.id);
        await downloadBlob(response);
        MatomoService.trackEvent(
          TrackingEvent.invoice().download(invoice.trackingType, invoice.id),
        );
      }
      this.$emit('refresh');
      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
    }
  }

  async approveQuotation(): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.quotationApprove(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.quotation.approve'));
      await this.fetchStatus();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.quotation.approve'),
        unwrapError(e),
      );
    }
  }

  async deleteQuotation(): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.delete(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.quotation.delete'));
      this.$emit('refresh');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.quotation.delete'),
        unwrapError(e),
      );
    }
  }

  async archiveQuotation(): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.quotationArchive(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.quotation.archive'));
      this.invoice.status = InvoiceStatus.ARCHIVED_QUOTATION;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.quotation.archive'),
        unwrapError(e),
      );
    }
  }

  get modalTitle(): string {
    if (!this.invoice) return '';
    return `${this.$tc('invoice.types.quotation')} ${this.invoice.number}`;
  }
}
