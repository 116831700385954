





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InvoiceService, PaymentMethodService } from '@/lib/services';
import { PaymentMethod } from '@/models/PaymentMethod';
import { format, startOfToday } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {},
})
export default class MarkPaymentModal extends Vue {
  @Prop({ required: true, type: String })
  invoiceId: string;

  loading: boolean = false;
  paymentMethods: PaymentMethod[] = [];
  selectedPaymentMethod: PaymentMethod | null = null;
  selectedDate: string = format(startOfToday(), DATE_FORMAT_ISO);

  async mounted(): Promise<void> {
    this.paymentMethods = await PaymentMethodService.all();
    if (this.paymentMethods.length > 0) {
      this.selectedPaymentMethod = this.paymentMethods[0];
    }
  }

  async onSubmit(): Promise<void> {
    if (!this.selectedPaymentMethod) return;
    this.loading = true;
    try {
      await InvoiceService.markPaid(
        this.invoiceId,
        this.selectedDate,
        this.selectedPaymentMethod.id,
      );
      this.$emit('marked');
      this.$toaster.success(this.$tc('messages.success.marked'));
      this.close();
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.marked'), unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit('close');
  }
}
