



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SkeletonLoader extends Vue {
  @Prop({ default: 1 })
  speed: number;

  @Prop({ default: 400 })
  width: number;

  @Prop({ default: 30 })
  height: number;

  get viewbox(): string {
    return `0 0 ${this.width} ${this.height}`;
  }

  get formattedSpeed(): string {
    return `${this.speed}s`;
  }

  get gradientId(): string {
    return `gradient-${this['_uid']}`;
  }

  get clipPathId(): string {
    return `clippath-${this['_uid']}`;
  }
}
