





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { clamp } from '@/lib/helpers';

@Component
export default class Pagination extends Vue {
  @Prop({ required: true, default: 10 })
  limit: number;
  @Prop({ required: true, default: 0 })
  totalEntries: number;
  @Prop({ required: true, default: 0 })
  totalPages: number;
  @Prop({ default: 'archive.labels.per-page' })
  pageCaption: string;

  page: number = 1;

  get currentFirst(): number {
    return ((this.page - 1) * this.limit + 1) * +(this.totalEntries > 0);
  }
  get currentLast(): number {
    return Math.min(this.page * this.limit, this.totalEntries);
  }

  updatePageLimit(event: Record<string, any>): void {
    this.$emit('limitUpdate', clamp(+event.target.value, 1, 100));
  }

  prevPage(): void {
    if (this.page <= 1) return;
    this.updatePage(this.page - 1);
  }

  nextPage(): void {
    if (this.page >= this.totalPages) return;
    this.updatePage(this.page + 1);
  }

  updatePage(page: number): void {
    this.page = page;
    this.$emit('loadPage', this.page);
  }
}
