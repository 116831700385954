





































































































































































































































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import StatusPanel from './StatusPanel.vue';
import CommentPanel from '../comments/CommentPanel.vue';
import FixLedgerModal from '@/components/archive/booking/FixLedger.vue';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import SendInvoiceModal, {
  ModalCallback,
} from '@/components/SendInvoiceModal.vue';
import MarkPaymentModal from '@/components/MarkPaymentModal.vue';
import {
  ActionButton,
  ActionButtonList,
  ActionRouterLink,
} from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';
import { Invoice, InvoiceTextEmail, InvoiceType } from '@/models/invoice';
import { InvoiceService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { EmailHint } from '@/lib/emailhint';
import { downloadBlob } from '@/lib/download';
import MenuDownload from '@/components/archive/lines/MenuDownload.vue';
import { IntegrationsService } from '@/lib/services/integrations';
import { Integration } from '@/models';
import { Getter } from 'vuex-class';
import { pickBy } from 'lodash-es';

@Component({
  components: {
    MenuDownload,
    CommentPanel,
    Popover,
    StatusPanel,
    ActionButtonList,
    ActionButton,
    ActionRouterLink,
    ViewInvoiceModal,
    SendInvoiceModal,
    MarkPaymentModal,
    FixLedgerModal,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineInvoice extends ArchiveLine {
  showPaymentModal: boolean = false;
  showCreditModal: boolean = false;
  showFixLedgerModal: boolean = false;
  integration: Integration | null = null;

  modalCallback: null | ModalCallback = null;
  sendHint: EmailHint | null = null;

  HINT_CREDIT = EmailHint.SEND;
  IntegrationService: IntegrationsService = new IntegrationsService();

  @Getter('settings/hasBookkeeping') hasBookkeeping: boolean;

  get hasBeenPaid(): boolean {
    if (!this.status) return false;

    return this.status.payment.status;
  }

  get isCredit(): boolean {
    if (!this.status) return false;

    return this.invoice.type === InvoiceType.CREDIT;
  }

  get hasAttachments(): boolean {
    if (!this.status) return false;

    return this.status.attachment.status;
  }

  get hasReminders(): boolean {
    if (!this.status) return false;

    return this.status.reminder.count > 0;
  }

  get isCreditlike(): boolean {
    if (!this.status) return false;

    return ['credit', 'credited'].includes(this.status.invoice.state);
  }

  async bookInvoice(checkMissing: boolean = true): Promise<void> {
    if (!this.invoice) return;

    if (checkMissing) {
      let missingLedgers: number[] = [];
      let missingVats: number[] = [];
      for (const line of this.invoice.lines) {
        if (
          line.ledger !== null &&
          (line.ledger.credit === null || line.ledger.credit === '')
        ) {
          // The null check should take descr-only into account
          missingLedgers.push(line.ledger.id);
        }
        if (
          line.vat !== null &&
          (line.vat.code === null || line.vat.code === '')
        ) {
          missingVats.push(line.vat.id);
        }
      }
      if (missingLedgers.length > 0 || missingVats.length > 0) {
        this.integration = await this.IntegrationService.check();

        // Show only the ledgers/vats that need to be mapped
        this.integration.config.option.vatcodes.names = pickBy(
          this.integration.config.option.vatcodes.names,
          (v, k) => missingVats.includes(parseInt(k)),
        );
        this.integration.config.option.ledgernumbers.names = pickBy(
          this.integration.config.option.ledgernumbers.names,
          (v, k) => missingLedgers.includes(parseInt(k)),
        );
        this.showFixLedgerModal = true;
        return;
      }
    }

    try {
      await InvoiceService.book(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.book', 1));
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.book', 1), unwrapError(e));
    }
  }

  async onFixed({
    name,
    props,
  }: {
    name: string;
    props: Record<string, any>;
  }): Promise<void> {
    try {
      await this.IntegrationService.update(name, props);
      this.showFixLedgerModal = false;
    } catch (e) {
      this.$toaster.error('Could not update integration', unwrapError(e));
    }

    await this.bookInvoice(false);
  }

  async remindInvoice(
    type: string,
    invoice: Invoice,
    emailtext: InvoiceTextEmail,
  ): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.remind(this.invoice.id);
      if (this.status) {
        this.status.reminder.count += 1;
      }
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.remind'), unwrapError(e));
      return;
    }
    return await this.sendInvoice(type, invoice, emailtext);
  }

  async downloadUbl(): Promise<void> {
    this.isDownloading = true;
    const blob = await InvoiceService.generateUbl(this.invoice.id);
    downloadBlob(blob);
    this.isDownloading = false;
  }

  doResend(): void {
    this.showSendModal = true;
    this.sendHint = EmailHint.SEND;
    this.modalCallback = this.sendInvoice.bind(this);
  }

  doRemind(): void {
    this.showSendModal = true;
    this.sendHint = EmailHint.REMIND;
    this.modalCallback = this.remindInvoice.bind(this);
  }

  async doMarkUnpaid(): Promise<void> {
    try {
      await InvoiceService.markUnpaid(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.unmarked'));
      await this.fetchStatus();
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.unmarked'), unwrapError(e));
    }
  }

  async sendCreditInvoice(
    type: string,
    invoice: Invoice,
    emailData: InvoiceTextEmail,
  ): Promise<void> {
    if (!this.invoice) return;

    let creditId: string;
    try {
      creditId = await InvoiceService.credit(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.credited'));
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.credited'), unwrapError(e));
      return;
    }

    let creditInvoice: Invoice;
    try {
      creditInvoice = await InvoiceService.get(creditId);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.load.invoices'),
        unwrapError(e),
      );
      return;
    }

    await this.sendInvoice(type, creditInvoice, emailData);
  }

  get descriptionPopoverClasses(): Record<string, boolean> {
    let width = 24;
    if (this.small) {
      width -= 2 * 4;
    }
    return {
      'fl-zero': true,
      [`fl-grow-${width}`]: true,
    };
  }

  get modalTitle(): string {
    if (!this.invoice) {
      return this.$tc('_.invoice');
    }

    return `${this.$tc('_.invoice')} ${this.invoice.number}`;
  }

  get customerNameShort(): string {
    if (this.invoice.receiver.name.length > 20) {
      return this.invoice.receiver.name.slice(0, 19) + '…';
    }
    return this.invoice.receiver.name;
  }
}
