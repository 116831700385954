export class TrackingEvent {
  constructor(
    public category: string,
    public action: string,
    public name?: string,
    public value?: string,
  ) {}

  public static invoice(): Record<
    string,
    (type: string, id: string) => TrackingEvent
  > {
    const c = 'Invoice';
    return {
      create(type: string, id: string) {
        return new TrackingEvent(c, 'Create', type, id);
      },

      edit(type: string, id: string) {
        return new TrackingEvent(c, 'Edit', type, id);
      },

      download(type: string, id: string) {
        return new TrackingEvent(c, 'Download', type, id);
      },

      email(type: string, id: string) {
        return new TrackingEvent(c, 'Email', type, id);
      },

      sms(type: string, id: string) {
        return new TrackingEvent(c, 'Sms', type, id);
      },
    };
  }
}
