





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import Popover from '@/components/Popover.vue';

@Component({
  components: {
    ActionButtonList,
    ActionButton,
    Popover,
  },
})
export default class StatusPanel extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly showReminder!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showAttachments!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showUbl!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly loading!: boolean;

  get hasOneAction(): boolean {
    return !(this.showReminder || this.showAttachments || this.showUbl);
  }
}
