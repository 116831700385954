




























































































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import StatusPanel from './StatusPanel.vue';
import CommentPanel from '@/components/archive/comments/CommentPanel.vue';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import {
  ActionButton,
  ActionButtonList,
  ActionRouterLink,
} from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';
import { unwrapError } from '@/lib/helpers';
import { InvoiceService } from '@/lib/services';

@Component({
  components: {
    Popover,
    StatusPanel,
    ActionButtonList,
    ActionButton,
    ActionRouterLink,
    ViewInvoiceModal,
    CommentPanel,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineRecurring extends ArchiveLine {
  async removeRecurring(): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.delete(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.delete.recurring'));
      this.$emit('refresh');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.delete.recurring'),
        unwrapError(e),
      );
    }
  }

  get recurStartDateFormatted(): string {
    if (this.invoice === null) return '';
    if (this.invoice.meta.recurring === null) return '';
    return this.invoice.meta.recurring.startDate.toLocaleDateString();
  }

  get descriptionPopoverClasses(): Record<string, boolean> {
    let width = 24;
    if (this.small) {
      width -= 2 * 4;
    }
    return {
      'fl-zero': true,
      [`fl-grow-${width}`]: true,
    };
  }
}
