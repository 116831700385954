













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { AnalyticsService } from '@/lib/services';

@Component
export default class TrafficLight extends Vue {
  invoicesSent: number = 0;
  quotationsOpen: number = 0;
  invoicesOpen: number = 0;

  async mounted(): Promise<void> {
    [this.invoicesSent, this.quotationsOpen, this.invoicesOpen] =
      await Promise.all([
        AnalyticsService.invoicesSentMonth(),
        AnalyticsService.quotationsOpen(),
        AnalyticsService.invoicesOpen(),
      ]);
  }
}
