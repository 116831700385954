









































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';

import { InvoiceMailStatusEvent, InvoiceStatus } from '@/lib/services/invoices';
import { Invoice, InvoiceStatus as InvoiceStati } from '@/models';
import { Timeline, TimelineItem } from '@/components/timeline';
import { parse } from 'date-fns';
import StatusTimeline from '@/components/archive/lines/StatusTimeline.vue';
import { Event, generateEventList } from '@/lib/statusevents';

@Component({
  components: {
    StatusTimeline,
    TimelineItem,
    Timeline,
    Popover,
  },
})
export default class StatusPanel extends Vue {
  @Prop({ required: true })
  readonly status!: InvoiceStatus;

  @Prop({ required: true })
  readonly invoice!: Invoice;

  @Prop({ default: true, type: Boolean })
  readonly showChecks!: boolean;

  eventList: Event[] = [];

  mounted(): void {
    this.eventList = generateEventList(this.status);
  }

  get statusIconColor(): string {
    if (this.invoice.isQuotation) {
      switch (this.status.quotation.message) {
        case 'ACCEPT':
          return 'status-green';
        case 'REJECT':
          return 'status-red';
        default:
          return 'status-orange';
      }
    }

    // Normal invoice
    switch (this.status.invoice.state) {
      case 'credit':
      case 'credited':
      case 'paid':
        return 'status-green';
      case 'pastdue':
        return 'status-red';
      case 'open':
      default:
        return 'status-orange';
    }
  }

  get statusIcon(): string {
    if (this.invoice.isQuotation) {
      switch (this.invoice.status) {
        case InvoiceStati.DRAFT_QUOTATION:
          return 'drafts';
        case InvoiceStati.ARCHIVED_QUOTATION:
          return 'work_outline';
      }
      switch (this.status.quotation.message) {
        case 'ACCEPT':
          return 'check_circle';
        case 'REJECT':
          return 'cancel';
        default:
          return 'watch_later';
      }
    }

    // Normal invoice
    switch (this.status.invoice.state) {
      case 'credit':
      case 'credited':
      case 'paid':
        return 'check_circle';
      case 'pastdue':
        return 'cancel';
      case 'open':
      default:
        return 'watch_later';
    }
  }

  get mailIconColor(): string {
    const statusmap = {
      queued: 0,
      dropped: 1,
      accepted: 0,
      sent: 0,
      delivered: 0,
      opened: 2,
    };

    const ev = this.lastMailEvent;
    if (!ev) {
      return '';
    }
    return ['', 'status-red', 'status-blue'][statusmap[ev.event] || 0];
  }

  get mailIcon(): string {
    const statusmap = {
      queued: 0,
      dropped: 1,
      accepted: 2,
      sent: 2,
      delivered: 2,
      opened: 2,
    };
    const ev = this.lastMailEvent;
    if (!ev) {
      return '';
    }
    return ['schedule', 'close', 'check'][statusmap[ev.event] || 0];
  }

  get showSecondCheck(): boolean {
    return (
      this.status.mail.events &&
      !!this.checkmarkMailEvents.find(
        (e) => e.event === 'delivered' || e.event === 'opened',
      )
    );
  }

  get isPaid(): boolean {
    return this.status.invoice.state === 'paid';
  }

  get isCredited(): boolean {
    return this.status.invoice.state === 'credited';
  }

  get checkmarkMailEvents(): InvoiceMailStatusEvent[] {
    return this.status.mail.events.filter(
      (e) => e.recipient === this.invoice.receiver.email,
    );
  }

  get lastMailEvent(): InvoiceMailStatusEvent | null {
    const sortedMailEvents = this.checkmarkMailEvents.sort(
      (a, b) => parse(b.time).getTime() - parse(a.time).getTime(),
    );
    return sortedMailEvents[0] || null;
  }
}
