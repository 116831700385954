



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Invoices from '@/components/archive/Invoices.vue';
import Fab from '@/components/Fab.vue';
import Graph from '@/components/Graph.vue';
import TrafficLight from '@/components/TrafficLight.vue';

@Component({
  components: {
    TrafficLight,
    Invoices,
    Fab,
    Graph,
  },
})
export default class AnalyticsPage extends Vue {
  renderMethods: string[] = ['turnover', 'customers'];
  currentRenderMethod: string = this.renderMethods[0];

  async next(): Promise<void> {
    const current = this.renderMethods.indexOf(this.currentRenderMethod);
    const next = (current + 1) % this.renderMethods.length;
    this.currentRenderMethod = this.renderMethods[next];
  }
}
