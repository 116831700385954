



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Popover from '@/components/Popover.vue';
import CommentTimeline from '@/components/archive/comments/CommentTimeline.vue';
import { InvoiceCommentsStatusItem } from '@/lib/services/invoices';
import { sleep } from '@/lib/helpers';

@Component({
  components: {
    CommentTimeline,
    Popover,
  },
})
export default class CommentPanel extends Vue {
  @Prop({ required: true })
  readonly comments!: InvoiceCommentsStatusItem[];
  @Prop({ required: true })
  readonly invoiceId!: number;
  @Prop({ default: false })
  readonly immediateOpen: boolean;

  hasImmediateOpened: boolean = false;

  @Getter('settings/userName')
  readonly currentUserName!: string;
  @Getter('auth/user')
  readonly currentUserId!: number;

  $refs: {
    timelineContainer: HTMLDivElement;
    addCommentField: HTMLInputElement;
    popover: Popover;
  };

  mounted(): void {
    if (this.immediateOpen && !this.hasImmediateOpened) {
      sleep(10).then(() => this.$refs.popover.open());
      this.hasImmediateOpened = true;
    }
  }
}
