










































































































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import ViewInvoiceModal from '@/components/ViewInvoiceModal.vue';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import CommentPanel from '@/components/archive/comments/CommentPanel.vue';
import {
  ActionButton,
  ActionButtonList,
  ActionRouterLink,
} from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';
import { InvoiceService, MatomoService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { Invoice, InvoiceTextEmail } from '@/models';
import { InvoiceSendType } from '@/lib/services/invoices';
import { downloadBlob } from '@/lib/download';
import { TrackingEvent } from '@/lib/trackingevent';

@Component({
  components: {
    Popover,
    ActionButtonList,
    ActionButton,
    ActionRouterLink,
    ViewInvoiceModal,
    SendInvoiceModal,
    CommentPanel,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineDraft extends ArchiveLine {
  async deleteDraft(): Promise<void> {
    if (!this.invoice) return;
    try {
      await InvoiceService.delete(this.invoice.id);
      this.$toaster.success(this.$tc('messages.success.delete.draft'));
      this.$emit('refresh');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.delete.draft'),
        unwrapError(e),
      );
    }
  }

  async sendInvoiceDraft(
    type: string,
    invoice: Invoice,
    emailData: InvoiceTextEmail,
  ): Promise<void> {
    try {
      await InvoiceService.send(invoice.id, InvoiceSendType.Mark, {
        shiftSendDate: true,
      });

      if (type === 'email') {
        // Email it
        await InvoiceService.send(invoice.id, InvoiceSendType.Mail, {
          text: emailData,
        });
        MatomoService.trackEvent(
          TrackingEvent.invoice().email(invoice.trackingType, invoice.id),
        );
      } else if (type === 'sms') {
        await InvoiceService.send(invoice.id, InvoiceSendType.Sms);
        MatomoService.trackEvent(
          TrackingEvent.invoice().sms(invoice.trackingType, invoice.id),
        );
      } else {
        // Download it
        const response = await InvoiceService.sendDownloadPdf(invoice.id);
        await downloadBlob(response);
        MatomoService.trackEvent(
          TrackingEvent.invoice().download(invoice.trackingType, invoice.id),
        );
      }
      this.$emit('refresh');
      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
    }
  }
}
