








































import { Component } from 'vue-property-decorator';

import ArchiveLine from './ArchiveLine';
import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';
import { SepaService } from '@/lib/services';
import { downloadBlob } from '@/lib/download';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButtonList,
    ActionButton,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class LineSepa extends ArchiveLine {
  async downloadSepa(): Promise<void> {
    this.isDownloading = true;
    try {
      const sepa = await SepaService.view(this.sepaCollection.id);
      downloadBlob(sepa);
      this.$toaster.success(this.$tc('messages.success.download.sepa'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.download.sepa'),
        unwrapError(e),
      );
    } finally {
      this.isDownloading = false;
    }
  }

  showInvoices(): void {
    this.$router.push({
      name: 'archive',
      query: { type: 'invoice', sepa_id: this.sepaCollection.id },
    });
  }
}
