






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Fab extends Vue {
  @Prop({ required: true })
  icon: string;
  @Prop({
    default: 'large',
    validator: (value: string): boolean => ['large', 'small'].includes(value),
  })
  size: string;

  onClick(...args: any[]): void {
    this.$emit('click', ...args);
  }
}
