














































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import * as lineComponents from './lines';
import Popover from '@/components/Popover.vue';
import Pagination from '@/components/Pagination.vue';
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { SepaDateModal } from '@/components/modals';
import FixLedgerModal from '@/components/archive/booking/FixLedger.vue';
import { money as moneyFilter } from '@/lib/filters/money';

import {
  default as InvoiceService,
  INVOICE_SEARCH_TYPES,
  InvoiceSearchType,
  InvoiceSendType,
  InvoiceTotals,
} from '@/lib/services/invoices';
import { Invoice, InvoiceTextEmail } from '@/models';
import { blobResponseToJson, headOrSelf, unwrapError } from '@/lib/helpers';
import { downloadBlob, generateInvoiceFileName } from '@/lib/download';
import { ArchiveEntry, ArchiveFilters } from './types';
import { MatomoService } from '@/lib/services';
import { TrackingEvent } from '@/lib/trackingevent';
import { Getter } from 'vuex-class';
import TotalsHeader from '@/components/archive/TotalsHeader.vue';
import { IntegrationsService } from '@/lib/services/integrations';
import { Integration } from '@/models';
import { pickBy } from 'lodash-es';

@Component({
  components: {
    TotalsHeader,
    ...lineComponents,
    Pagination,
    SkeletonLoader,
    Popover,
    ActionButton,
    ActionButtonList,
    SendInvoiceModal,
    SepaDateModal,
    FixLedgerModal,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class Invoices extends Vue {
  @Prop({
    required: true,
    validator: (x) => INVOICE_SEARCH_TYPES.includes(x),
  })
  type: InvoiceSearchType;

  @Prop({ default: false })
  small: boolean;

  @Prop({ default: () => new ArchiveFilters() })
  filters: ArchiveFilters;

  @Getter('settings/isViewOnly') viewOnly: boolean;
  @Getter('settings/hasBookkeeping') hasBookkeeping: boolean;

  newFilters: ArchiveFilters = new ArchiveFilters();

  entries: ArchiveEntry[] = [];
  loading: boolean = true;
  limit: number = 10;
  count: number = this.limit;
  page: number = 0;
  totalEntries: number = 0;
  totalPages: number = 0;

  searchString: string = '';

  currentSort: string = 'number';
  currentReverse: boolean = true;
  totals: InvoiceTotals | null = null;

  // Debounced wrapper around loadPage
  updatePage = debounce((x: number = 1) => this.loadPage(x), 500);

  onSearchInput = debounce(() => {
    this.loadPage(1);
    this.updateTotals();
  }, 500);

  isDraft: boolean = false;
  showSendModal: boolean = false;
  showSepaModal: boolean = false;

  IntegrationService: IntegrationsService = new IntegrationsService();
  showFixLedgerModal: boolean = false;
  integration: Integration | null = null;

  loaderWidth: number = 0;

  $refs: {
    loader: HTMLDivElement;
  };

  async mounted(): Promise<void> {
    const promises: Promise<any>[] = [];
    if (this.small) {
      this.limit = this.count = 5;
    } else {
      promises.push(this.updateTotals());
    }

    if (this.type === 'draft') {
      this.isDraft = true;
    }

    this.loaderWidth = Math.round(this.$refs.loader.clientWidth * 0.6);

    this.currentSort = this.defaultSort(headOrSelf(this.type));

    Object.assign(this.newFilters, this.filters);
    promises.push(this.loadPage());

    await Promise.all(promises);
  }

  async refresh(): Promise<void> {
    await this.loadPage(this.page);
  }

  async updatePageLimit(limit: number): Promise<void> {
    this.limit = limit;
    await this.updatePage(1);
  }

  async loadPage(page: number = 1): Promise<void> {
    this.loading = true;
    try {
      const results = await InvoiceService.search(page, {
        ...this.minimalFilters,
        ...(this.$route.query.sepa_id && {
          sepa_id: this.$route.query.sepa_id,
        }),
        order: this.currentSort,
        limit: +this.limit,
        reverse: this.currentReverse,
        ...(this.searchString.length > 0 && { term: this.searchString }),
        type: this.type || INVOICE_SEARCH_TYPES[0],
      });

      this.entries = results.items.map((e) => ({
        id: e ? e.id : '',
        selected: false,
        data: e,
      }));

      this.page = results.current;
      this.totalEntries = results.total_items;
      this.totalPages = results.last;
      this.count = results.items.length;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.load.invoices'),
        unwrapError(e),
      );
    }

    this.loading = false;
  }

  sort(column: string): void {
    if (this.currentSort === column) {
      this.currentReverse = !this.currentReverse;
    } else {
      this.currentSort = column;
      this.currentReverse = true;
    }
    this.updatePage();
  }

  sortClasses(column: string): Record<string, boolean> {
    const active = this.currentSort === column;
    return {
      sortable: true,
      'sort-active': active,
      'sort-asc': active && !this.currentReverse,
      'sort-desc': active && this.currentReverse,
    };
  }

  get selectedInvoices(): ArchiveEntry[] {
    return this.entries.filter((e) => e.selected);
  }

  get selectedInvoicesCount(): number {
    return this.selectedInvoices.length;
  }

  // Checks if any of the selected invoices has non-euro currency
  get sepaIsPossible(): boolean {
    return this.selectedInvoices.every(
      (i) => (i.data as Invoice).meta.currency === 'EUR',
    );
  }

  get invoiceLineComponent(): string {
    switch (this.type) {
      case 'draft':
        return 'LineDraft';
      case 'quotation':
        return 'LineQuotation';
      case 'recurring':
        return 'LineRecurring';
      case 'invoice':
      default:
        return 'LineInvoice';
    }
  }

  @Watch('$route')
  async onRouteUpdate(): Promise<void> {
    if (this.type !== this.$route.query['type']) {
      this.$emit('changetype', this.$route.query['type']);
      this.currentSort = this.defaultSort(
        headOrSelf(this.$route.query['type'] || this.type)!,
      );
    }
    Object.assign(this.filters, new ArchiveFilters(), this.$route.query);
    await Promise.all([
      this.updatePage(),
      this.updateTotals(),
    ] as Promise<any>[]);
  }

  async applyFilters(): Promise<void> {
    await this.$router.replace({
      name: 'archive',
      query: {
        type: this.type,
        ...this.newFilters.minimal,
      },
    });
  }

  async resetFilters(): Promise<void> {
    this.newFilters = new ArchiveFilters();
  }

  // TODO(joost 11-10-2021): Explicit typing breaks the search function
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get minimalFilters() {
    const obj: any = {};
    for (const [k, v] of Object.entries(this.filters)) {
      if (v !== null) {
        obj[k] = v;
      }

      if (v instanceof Array) {
        obj[k] = v.join(',');
      }
    }
    return obj;
  }

  updateSelected(id: string, value: boolean): void {
    const entry = this.entries.find((e) => e.id === id);
    if (!entry) return;

    entry.selected = value;
  }

  selectAll(event: Event): void {
    const newState = (event as any).target.checked;
    this.entries.forEach((e) => (e.selected = newState));
  }

  unselectAll(): void {
    const checkbox = this.$refs['selectall-checkbox'] as HTMLInputElement;
    checkbox.checked = false;
  }

  async deleteSelected(): Promise<void> {
    const count = this.selectedInvoicesCount;
    const confirmation = confirm(
      this.$tc('messages.confirm.delete.invoice', count, { count }),
    );

    if (confirmation) {
      const ids = this.selectedInvoices.map((e) => e.id);
      try {
        await InvoiceService.delete(...ids);
        this.$toaster.success(
          this.$tc('messages.success.delete.invoice', count, { count }),
        );
        this.unselectAll();
      } catch (e) {
        this.$toaster.error(
          this.$tc('messages.error.delete.invoice'),
          unwrapError(e),
        );
      } finally {
        await this.refresh();
      }
    }
  }

  sendSelected(): void {
    this.showSendModal = true;
  }

  async sendCallback(
    type: string,
    invoice: Invoice,
    emaildata: InvoiceTextEmail,
  ): Promise<void> {
    const ids = this.selectedInvoices.map((e) => e.id).join(',');
    try {
      await InvoiceService.send(ids, InvoiceSendType.Queue, {
        text: emaildata,
      });
      MatomoService.trackEvent(TrackingEvent.invoice().email('Many', ids));
      this.$toaster.success(
        this.$tc('messages.success.created.invoice-queued'),
      );
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.invoice-queued'),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  async downloadSelected(): Promise<void> {
    const ids = this.selectedInvoices.map((e) => e.id);
    try {
      this.$toaster.info(
        this.$tc('messages.info.download.invoice', ids.length, {
          count: ids.length,
        }),
      );
      const blob = await InvoiceService.view(ids.join(','));
      MatomoService.trackEvent(
        TrackingEvent.invoice().download('Many', ids.join(',')),
      );
      if (this.selectedInvoicesCount > 1) {
        downloadBlob(blob, `invoices.zip`);
      } else {
        downloadBlob(
          blob,
          generateInvoiceFileName(this.selectedInvoices[0].data as Invoice),
        );
      }
    } catch (e) {
      if ((e as any).response) {
        (e as any).response.data = await blobResponseToJson(
          (e as any).response,
        );
      }
      this.$toaster.error(
        this.$tc('messages.error.download.invoice', ids.length, {
          count: ids.length,
        }),
        unwrapError(e),
      );
    }
  }

  async sepaSelected(date: Date): Promise<void> {
    const ids = this.selectedInvoices.map((e) => e.id);
    try {
      const sepa = await InvoiceService.sepa(ids, date);
      downloadBlob(sepa);
      this.$toaster.success(this.$tc('messages.success.download.sepa'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.download.sepa'),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  async updateTotals(): Promise<void> {
    try {
      this.totals = await InvoiceService.searchTotals({
        ...this.minimalFilters,
        ...(this.$route.query.sepa_id && {
          sepa_id: this.$route.query.sepa_id,
        }),
        ...(this.searchString.length > 0 && { term: this.searchString }),
        type: this.type || INVOICE_SEARCH_TYPES[0],
      });
    } catch {
      this.totals = null;
    }
  }

  async downloadCsv(): Promise<void> {
    try {
      const csv = await InvoiceService.searchCsv({
        ...this.minimalFilters,
        type: this.type || INVOICE_SEARCH_TYPES[0],
      });

      downloadBlob(csv, this.type + '.csv');
      this.$toaster.success(this.$tc('messages.success.download.csv'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.download.csv'),
        unwrapError(e),
      );
    }
  }

  async syncPayments(): Promise<void> {
    this.$toaster.info(this.$tc('messages.info.sync.payments'));
    try {
      await InvoiceService.syncPayments();
      this.$toaster.success(this.$tc('messages.success.sync.payments'));
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.sync.payments'),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  async bookInvoices(checkMissing: boolean = true): Promise<void> {
    const selectedEntries: ArchiveEntry[] = this.entries.filter(
      (e) => e.selected,
    );
    const ids = selectedEntries.map((e) => e.id);
    if (!ids) return;

    if (checkMissing) {
      let missingLedgers: number[] = [];
      let missingVats: number[] = [];

      for (const entry of selectedEntries) {
        for (const line of entry.data ? entry.data.lines : []) {
          if (
            line.ledger !== null &&
            (line.ledger.credit === null || line.ledger.credit === '')
          ) {
            // The null check should take descr-only into account
            missingLedgers.push(line.ledger.id);
          }
          if (
            line.vat !== null &&
            (line.vat.code === null || line.vat.code === '')
          ) {
            missingVats.push(line.vat.id);
          }
        }
      }
      if (missingLedgers.length > 0 || missingVats.length > 0) {
        this.integration = await this.IntegrationService.check();

        // Show only the ledgers/vats that need to be mapped
        this.integration.config.option.vatcodes.names = pickBy(
          this.integration.config.option.vatcodes.names,
          (v, k) => missingVats.includes(parseInt(k)),
        );
        this.integration.config.option.ledgernumbers.names = pickBy(
          this.integration.config.option.ledgernumbers.names,
          (v, k) => missingLedgers.includes(parseInt(k)),
        );
        this.showFixLedgerModal = true;
        return;
      }
    }

    try {
      await InvoiceService.book(...ids);
      this.$toaster.success(this.$tc('messages.success.book', ids.length));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.book', ids.length),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  async onFixed({
    name,
    props,
  }: {
    name: string;
    props: Record<string, any>;
  }): Promise<void> {
    try {
      this.showFixLedgerModal = false;
      await this.IntegrationService.update(name, props);
    } catch (e) {
      this.$toaster.error('Could not update integration', unwrapError(e));
    }

    await this.bookInvoices(false);
  }

  async approveQuotation(): Promise<void> {
    try {
      await InvoiceService.quotationApprove(
        ...this.selectedInvoices.map((e) => e.id),
      );
      this.$toaster.success(this.$tc('messages.success.quotation.approve'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.quotation.approve'),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  async archiveQuotation(): Promise<void> {
    try {
      await InvoiceService.quotationArchive(
        ...this.selectedInvoices.map((e) => e.id),
      );
      this.$toaster.success(this.$tc('messages.success.quotation.archive'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.quotation.archive'),
        unwrapError(e),
      );
    } finally {
      await this.refresh();
    }
  }

  defaultSort(type: string): string {
    switch (type.toLowerCase()) {
      case 'draft':
      case 'recurring':
        return 'id';
      default:
        return 'number';
    }
  }
}
